import React from 'react';
import { md2react } from 'helpers/parser';

interface ContactLocationProps {
  city: string;
  address: string;
  cta: { url: string; label: string };
  imageUrl?: string;
}

const ContactLocation: React.FC<ContactLocationProps> = ({
  city,
  address,
  cta,
  imageUrl,
}) => {
  return (
    <div className="flex flex-col items-center md:w-80 text-dark-grey">
      <div
        className="w-32 h-32 bg-gray-200 rounded-full mb-14 bg-cover bg-center"
        style={{ backgroundImage: imageUrl ? `url(${imageUrl})` : undefined }}
      />
      <h3 className="mb-3 u-h3">{city}</h3>
      <div className="mb-5">{md2react(address)}</div>
      <a
        className="u-cta-link"
        href={cta.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {cta.label}
      </a>
    </div>
  );
};

export default ContactLocation;
