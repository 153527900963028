import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import ContactDepartmentsSection from 'components/organisms/ContactDepartmentsSection';
import ContactHero from 'components/organisms/ContactHero';
import ContactLinksSection from 'components/organisms/ContactLinksSection';
import ContactLocationsSection from 'components/organisms/ContactLocationsSection';
import CtaSection from 'components/organisms/CtaSection';

const ContactPageContent = ({
  hero,
  departments,
  locationsHeading,
  locations,
  ctaSection,
}) => {
  return (
    <>
      <ContactHero
        title={hero?.title}
        body={hero?.body}
        background="bg-primary bg-opacity-15"
      />
      <ContactDepartmentsSection
        departments={departments.map((department) => ({
          title: department?.title,
          description: department?.body,
          phone: department?.phone,
          email: department?.email,
          cta: department?.primaryCta,
          secondaryCta: department?.secondaryCta,
        }))}
      />
      <ContactLocationsSection
        title={locationsHeading}
        locations={locations.map((location) => ({
          city: location?.city,
          imageUrl: location?.image,
          address: location?.address,
          cta: { url: location?.directionsUrl, label: 'Get directions' },
        }))}
      />
      <ContactLinksSection
        links={[
          { to: '/about', label: 'Our Story' },
          // {
          //   to: '/press',
          //   label: 'Press Center',
          //   backgroundPosition: 'center right 52px',
          // },
          {
            to: '/careers',
            label: 'Open Positions',
            backgroundPosition: 'left 108px center',
          },
        ]}
      />
      <CtaSection headline={ctaSection?.heading} />
    </>
  );
};

export const ContactPreview = ({ entry }) => {
  return <ContactPageContent {...entry.get('data').toJS()} />;
};

const ContactPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout headerBackground="bg-primary bg-opacity-15">
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />
      <ContactPageContent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ContactPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/contact/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          body
        }
        departments {
          title
          body
          phone
          email
          primaryCta {
            label
            url
          }
          secondaryCta {
            label
            url
          }
        }
        locationsHeading
        locations {
          city
          address
          directionsUrl
          image
        }
        ctaSection {
          heading
        }
      }
    }
  }
`;

export default ContactPage;
