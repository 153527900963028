import React from 'react';
import ContactLocation from 'components/molecules/ContactLocation';
import { html2react } from 'helpers/parser';

interface ContactLocation {
  city: string;
  address: string;
  cta: { url: string; label: string };
}

interface ContactLocationsSectionProps {
  title: string;
  locations: ContactLocation[];
}

const ContactLocationsSection: React.FC<ContactLocationsSectionProps> = ({
  title,
  locations,
}) => {
  return (
    <div className="mt-20 md:mt-32 bg-tile">
      <div className="container max-w-screen-xl py-20 mx-auto text-center md:py-32">
        <h2 className="mx-auto u-h2 md:w-120 text-dark-grey">
          {html2react(title)}
        </h2>
        <div className="justify-around mt-16 md:mt-32 sm:flex md:max-w-4xl md:mx-auto">
          {locations.map((location, index) => (
            <div key={index} className="mx-4 mb-16 last:mb-0 sm:mb-0">
              <ContactLocation {...location} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactLocationsSection;
