import React from 'react';
import ContactDepartment from 'components/molecules/ContactDepartment';

interface ContactDepartmentData {
  title: string;
  description: string;
  phone: string;
  email: string;
  cta: { label: string; url: string };
  secondaryCta?: { label: string; url: string };
}

interface ContactDepartmentsSectionProps {
  departments: ContactDepartmentData[];
}

const ContactDepartmentsSection: React.FC<ContactDepartmentsSectionProps> = ({
  departments,
}) => {
  return (
    <div className="my-20 md:my-32">
      <div className="container max-w-screen-xl mx-auto">
        <div className="px-6 lg:px-md lg:flex">
          {departments.map((department, index) => (
            <div
              key={index}
              className="flex-1 mb-16 md:pr-32 lg:mb-0 last:mb-0"
            >
              <ContactDepartment {...department} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactDepartmentsSection;
