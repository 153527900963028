import { Link } from 'gatsby';
import React from 'react';

interface CTAProps {
  label: string;
  url: string;
  className?: string;
}

interface ContactDepartmentProps {
  title: string;
  description: string;
  phone: string;
  email: string;
  cta: { label: string; url: string };
  secondaryCta?: { label: string; url: string };
}

const CtaButton: React.FC<CTAProps> = ({ className = '', label, url }) => {
  const openLiveAgent = async () => {
    console.log('trigger')
    if (
      (window as any).embedded_svc &&
      (window as any).embedded_svc.liveAgentAPI
    ) {
      await (window as any).embedded_svc.liveAgentAPI.startChat();
    }
  };

  if (url === 'OPEN_LIVE_AGENT') {
    return (
      <a onClick={openLiveAgent} className={className}>
        {label}
      </a>
    );
  } else {
    return (
      <Link to={url} className={className}>
        {label}
      </Link>
    );
  }
};

const ContactDepartment: React.FC<ContactDepartmentProps> = ({
  title,
  description,
  phone,
  email,
  cta,
  secondaryCta,
}) => {
  return (
    <div>
      <h2 className="mb-3 u-h3 max-w-sm">{title}</h2>
      <p className="pr-6 mb-6 text-lg lg:text-xl md:pr-0">{description}</p>
      <div className="mb-6 flex lg:mb-12">
        <div className="mb-5 md:mb-0">
          {phone && phone.length > 0 && (
            <a href={`tel:${phone}`} className="u-cta-link mr-8">
              {phone}
            </a>
          )}
        </div>
        <div>
          <a href={`mailto:${email}`} className="u-cta-link">
            {email}
          </a>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center md:space-x-5 space-y-5 md:space-y-0">
        <CtaButton
          className="u-btn u-btn--green w-full md:w-auto"
          label={cta.label}
          url={cta.url}
        />

        {secondaryCta && (
          <CtaButton
            className="u-btn u-btn--outline"
            label={secondaryCta.label}
            url={secondaryCta.url}
          />
        )}
      </div>
    </div>
  );
};

export default ContactDepartment;
